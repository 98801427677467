import { sendAppClientDataEvent } from '@/core/xstate';
import { KsAppClientDataNoMeta } from '@knapsack/types';

export function enablePrototyping({
  patternsState,
  settings,
}: {
  patternsState: KsAppClientDataNoMeta['patternsState'];
  settings: KsAppClientDataNoMeta['db']['settings']['prototyping'];
}) {
  // renderers that exist in Knapsack config
  const availableRendererIds = Object.values(patternsState.renderers)
    .filter((r) => r.meta.prototypingTemplate)
    .map((r) => r.meta.id);

  // renderers already enabled in the prototyping pattern
  const enabledRendererIds = [
    ...new Set(
      patternsState.patterns[settings?.patternId]?.templates.flatMap(
        (t) => t.templateLanguageId,
      ),
    ),
  ];

  // renderers disabled in the prototyping settings
  const disabledRendererIds = settings?.disabledRendererIds || [];

  // renderers that should be enabled
  const newEnabledRendererIds = availableRendererIds.filter(
    (id) =>
      !enabledRendererIds.includes(id) && !disabledRendererIds.includes(id),
  );

  if (newEnabledRendererIds.length) {
    setTimeout(() => {
      sendAppClientDataEvent({
        type: 'proto.setEnabledRenderers',
        enabledRenderers: newEnabledRendererIds,
      });
    }, 5000);
  }
}
