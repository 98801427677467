import { Spinner } from '@knapsack/toby';
import { SectionLayout } from '@/core/layout/section-layout';
import { appLoadingIndicator } from './app-loading-indicator.css';

export const AppLoadingIndicator = () => (
  <SectionLayout>
    <div className={appLoadingIndicator} data-testid="app-loading-indicator">
      <Spinner size="medium" label="Loading" />
    </div>
  </SectionLayout>
);
