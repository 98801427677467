'use client';

import { useEffect } from 'react';
import { AppLoadingIndicator } from '@/components/app-loading-indicator/app-loading-indicator';
import {
  sendAppEvent,
  useAppClientDataCtxSelector,
  useAppStateMatches,
} from '@/core';
import { isSiteWithCustomLandingPage } from '@/core/pages/custom-landing-page';
import { Banner, TextBody } from '@knapsack/toby';
import { useAsync } from '@knapsack/hooks';
import { TextLink } from '@/components/text-link';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import {
  loadSiteFromUrlParams,
  SiteLoadError,
} from '@/core/site/utils/load-site';
import { matchCustomToSpecificSiteInstance } from '@/core/site/utils/site-loading-utils';
import { SuspenseAndErrorCatcher } from '@/utils/error-catcher';
import { getBranchStatusContent } from '@/domains/branches/components/intercepts/branch-status-intercept';
import { canInstanceStatusBeAccessed, DOCS_URLS } from '@knapsack/core';
import { setGlobalContextProperty } from '@/services/datadog';

const ErrorPage = dynamic(() => import('@/core/pages/error-page'));
const LoginPage = dynamic(() => import('./login-page'));
const CustomLandingPage = dynamic(
  () => import('@/core/pages/custom-landing-page/custom-landing-page'),
);
export default function SiteInstanceLayout({
  children,
  params: { siteId: siteIdUrlParam, instanceId: instanceIdUrlParam },
}: {
  children: React.ReactNode;
  params: { siteId: string; instanceId: string };
}) {
  const isSigningOut = useAppStateMatches('user.loggedIn.loggingOut');
  const isUserUnknown = useAppStateMatches('user.unknown');

  const router = useRouter();

  const {
    loading,
    error,
    value: siteData,
  } = useAsync(async () => {
    if (!siteIdUrlParam || !instanceIdUrlParam) return;
    if (isUserUnknown) return;

    const isCustomSiteInstance = siteIdUrlParam === 'custom';
    /**
     * On Vercel deploys, the instanceIdUrlParam is encoded twice.
     * This is because the instanceIdUrlParam is a string that contains a URL, and the URL is encoded twice.
     * The first encoding is done by the Next.js router, and the second encoding is done by the browser.
     * We need to decode the instanceIdUrlParam twice to get the original URL.
     * Decoding twice on a param that is encoded once is ok
     */
    const customUrl = decodeURIComponent(
      decodeURIComponent(instanceIdUrlParam),
    );

    const isLocal =
      customUrl.includes('localhost') || customUrl.includes('127.0.0.1');
    const siteLoadResults = await loadSiteFromUrlParams(
      isCustomSiteInstance
        ? {
            type: 'custom',
            customUrl,
          }
        : {
            type: 'site',
            siteId: siteIdUrlParam,
            instanceId: instanceIdUrlParam,
          },
    );
    sendAppEvent({
      type: 'site.loadInstance',
      data: siteLoadResults,
    });
    // waiting a tick to allow the app state to update
    await new Promise((resolve) => setTimeout(resolve, 0));
    if (isCustomSiteInstance && !isLocal) {
      matchCustomToSpecificSiteInstance(customUrl)
        .then((result) => {
          if (!result.hasMatch) return;
          // yes, this could cause a double load, but it's a rare case so we'd rather be faster
          router.push(
            `/site/${result.siteId}/${
              result.isLatestInstance ? 'latest' : result.instanceId
            }`,
          );
        })
        .catch(console.error);
    }
    return siteLoadResults;
  }, [siteIdUrlParam, instanceIdUrlParam, isUserUnknown]);

  useEffect(() => {
    setGlobalContextProperty('knapsackMeta', {
      siteId: siteIdUrlParam,
      instanceId: instanceIdUrlParam,
    });
  }, [siteIdUrlParam, instanceIdUrlParam]);

  const loadingStatus: 'pending' | 'error' | 'success' | 'initial' = (() => {
    if (loading) return 'pending';
    if (error) return 'error';
    if (siteData) return 'success';
    return 'initial';
  })();

  const siteStatus = siteData?.site?.meta?.status;
  const loaderError = useAppClientDataCtxSelector((ctx) => ctx.loaderError);

  if (loaderError) {
    return <Banner type="error" title={loaderError} />;
  }
  switch (loadingStatus) {
    case 'success': {
      switch (siteStatus) {
        case 'PREPARING': {
          return <h1>Preparing...</h1>;
        }
        case 'DISABLED': {
          return (
            <ErrorPage
              graphic="update-required"
              title="Your workspace is currently disabled for planned maintenance."
              message={
                <TextBody size="large">
                  Please contact support at{' '}
                  <TextLink href="mailto:help@knapsack.cloud" external>
                    help@knapsack.cloud
                  </TextLink>{' '}
                  for more information.
                </TextBody>
              }
            />
          );
        }
        // @todo figure out difference between these two, likely consolidate
        case 'INACTIVE':
        case 'ARCHIVED': {
          return (
            <ErrorPage
              graphic="site-id-not-found"
              title={
                siteStatus === 'ARCHIVED'
                  ? 'This workspace has been archived'
                  : 'This workspace is inactive'
              }
              message={
                <TextBody size="large">
                  Please contact support at{' '}
                  <TextLink href="mailto:help@knapsack.cloud" external>
                    help@knapsack.cloud
                  </TextLink>{' '}
                  for more information.
                </TextBody>
              }
            />
          );
        }
        case 'ACTIVE': {
          if (
            siteData?.site?.contentSrc?.type === 'cloud-authoring' &&
            siteData.site.contentSrc.instance.type === 'branch'
          ) {
            const { instanceStatus } = siteData.site.contentSrc.instance;
            if (!canInstanceStatusBeAccessed({ instanceStatus })) {
              const { body, title, subtitle } = getBranchStatusContent({
                instanceStatus,
              });
              function getGraphic() {
                switch (instanceStatus) {
                  case 'DELETED':
                    return 'status-deleted';
                  case 'PUBLISHED':
                  case 'PUBLISHING':
                    return 'status-published';
                  default:
                    return 'unknown';
                }
              }
              return (
                <ErrorPage
                  graphic={getGraphic()}
                  title={title}
                  message={
                    <>
                      <TextBody size="large">{subtitle}</TextBody>
                      <TextBody size="medium">{body}</TextBody>
                    </>
                  }
                />
              );
            }
          }
          // We're good to go!
          return children;
        }
        default: {
          const _exhaustiveCheck: never = siteStatus;
          return (
            <ErrorPage
              title={`Workspace has an unknown status in our DB: ${siteStatus}`}
            />
          );
        }
      }
    }
    case 'error': {
      const siteError =
        error instanceof SiteLoadError
          ? {
              title: error.message,
              info: error.info,
            }
          : { title: error.message };
      switch (siteError?.info?.type) {
        case 'outdated-app-client': {
          const { requiredVersion, currentVersion } = siteError.info;
          return (
            <ErrorPage
              graphic="update-required"
              title={siteError.title}
              message={
                <TextBody size="large">
                  Required version: {requiredVersion}
                  <br />
                  Current version: {currentVersion}
                  <br />
                  Run `npx @knapsack/update@latest` to update.
                </TextBody>
              }
              actions={[
                {
                  label: 'Docs on updating',
                  onClick: DOCS_URLS.UPDATING_KNAPSACK,
                },
              ]}
            />
          );
        }
        case 'siteId-not-found': {
          return (
            <ErrorPage graphic="site-id-not-found" title={siteError.title} />
          );
        }
        case 'unknown': {
          return <ErrorPage graphic="unknown" title={siteError.title} />;
        }
        case 'app-client-connection-failed': {
          return <ErrorPage graphic="no-connection" title={siteError.title} />;
        }
        case 'app-client-data-fetch-failed': {
          return (
            <ErrorPage
              graphic="no-connection"
              title={siteError.title}
              message={
                <TextBody size="large">
                  We&apos;re having trouble accessing your server. This might be
                  due to a temporary issue or a network problem.
                  <br />
                  <br />
                  Let your technical team know, so they can check the server and
                  ensure everything is running smoothly.
                </TextBody>
              }
            />
          );
        }
        case 'app-client-deployments-not-set-up': {
          return (
            <ErrorPage
              graphic="update-required"
              title={siteError.title}
              actions={[
                {
                  label: 'Docs on how to run locally',
                  onClick: DOCS_URLS.RUNNING_KNAPSACK_LOCALLY,
                },
              ]}
            />
          );
        }
        case 'private-site.user-not-logged-in': {
          const { siteId } = siteError.info;
          // Custom landing/sign in pages per-customer
          if (isSiteWithCustomLandingPage(siteId)) {
            return <CustomLandingPage siteId={siteId} />;
          }
          return (
            <SuspenseAndErrorCatcher>
              <LoginPage siteId={siteId} />
            </SuspenseAndErrorCatcher>
          );
        }
        case 'private-site.user-not-allowed-to-view-site': {
          return (
            <ErrorPage
              graphic="no-access"
              title="You don't have access to this workspace"
              actions={[
                {
                  label: 'Switch workspace',
                  onClick: () => router.push('/'),
                },
                {
                  label: isSigningOut ? 'Signing out...' : 'Log out',
                  onClick: () => sendAppEvent('user.signOut'),
                  loading: isSigningOut,
                },
              ]}
            />
          );
        }
        default: {
          const _exhaustiveCheck: never = siteError.info;
          return <ErrorPage title={siteError.title} />;
        }
      }
    }
    case 'initial':
    case 'pending': {
      return <AppLoadingIndicator />;
    }
    default: {
      const _exhaustiveCheck: never = loadingStatus;
      return (
        <ErrorPage
          title={`Workspace experienced an unknown loading state: ${loadingStatus}`}
        />
      );
    }
  }
}
